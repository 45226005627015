import Swiper from "swiper";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["swiperEl", "nextEl", "prevEl"];
  connect() {
    this.swiper = new Swiper(this.swiperElTarget, {
      modules: [Autoplay, Navigation, Pagination],
      speed: 1000,
      slidesPerView: 1,
      autoplay: {
        delay: 5000,
      },
      spaceBetween: 64,
      loop: true,
      navigation: {
        nextEl: this.nextElTarget,
        prevEl: this.prevElTarget,
      },
      pagination: {
        el: ".Carousel-pagination",
        clickable: true,
        bulletActiveClass: "Carousel-bulletActive",
        bulletClass: "Carousel-bullet",
      },
    });
  }
}
