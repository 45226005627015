import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["subNavEl"];
  connect() {
    // Binds
    this.openSubNav = this.openSubNav.bind(this);
  }

  openSubNav() {
    this.subNavElTarget.classList.add("active");
    this.element.classList.add("active");
  }

  closeSubNav() {
    this.subNavElTarget.classList.remove("active");
    this.element.classList.remove("active");
  }
}
