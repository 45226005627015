import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["subMenuEl"];
  connect() {
    // Binds
    this.openSubMenu = this.openSubMenu.bind(this);
  }

  openSubMenu(event) {
    event.preventDefault();
    event.target.classList.toggle("active");
    this.subMenuElTarget.classList.toggle("active");
  }
}
