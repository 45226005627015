import { Controller } from "@hotwired/stimulus";
import lottie from "lottie-web";
import textileRecyclingAnim from ".././animations/textileRecycling.json";
import textileInnovationAnim from ".././animations/textileInnovation.json";
import textileReuseAnim from ".././animations/textileReuse.json";
import { useIntersection } from "stimulus-use";

export default class extends Controller {
  static targets = ["lottieContainerEl"];
  static values = {
    animation: String,
  };

  connect() {
    this.animations = {
      textileRecycling: textileRecyclingAnim,
      textileInnovation: textileInnovationAnim,
      textileReuse: textileReuseAnim,
    };

    useIntersection(this);

    lottie.loadAnimation({
      container: this.element,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: this.animations[this.animationValue],
    });
  }

  appear() {
    try {
      lottie.play();
    } catch (err) {
      // Low battery iPhone etc.
    }
  }
}
