import { Controller } from "@hotwired/stimulus";
import { HEADERS } from "./utils";
import * as Sentry from "@sentry/browser";

export default class extends Controller {
  connect() {
    this.submitting = false;
  }

  async submit() {
    this.submitting = true;

    try {
      const response = await fetch(this.element.getAttribute("action"), {
        method: "POST",
        headers: HEADERS,
        body: new FormData(this.element),
      });
      if (response.redirected) {
        window.location.href = response.url;
        return;
      }
      if (response.status !== 200) {
        throw new Error(
          "Sorry, we could not submit this form. Please check you have cookies enabled, then refresh this page and try again."
        );
      }
      const text = await response.text();
      this.element.innerHTML = text;
    } catch (error) {
      this.element.innerText = error;
      Sentry.captureMessage("Form error");
    } finally {
      this.submitting = false;
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.submitting) return;
    this.submit();
  }
}
