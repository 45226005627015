import { Controller } from "@hotwired/stimulus";
import { useIntersection } from "stimulus-use";

export default class extends Controller {
  connect() {
    useIntersection(this);
    this.playing = false;
  }

  async appear() {
    if (this.playing) return;
    this.playing = true;
    try {
      this.element.classList.add("active");
      await this.element.play();
    } catch (err) {
      // Low battery iPhone etc.
    }
  }
}
