import "../scss/index.scss";

import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
import { Application } from "@hotwired/stimulus";

import AjaxForm from "./AjaxForm";
import AutoplayingVideo from "./AutoplayingVideo";
import MobileMenu from "./MobileMenu";
import Burger from "./Burger";
import FadeOnScroll from "./FadeOnScroll";
import Carousel from "./Carousel";
import Scroll from "./Scroll";
import LottiePlayer from "./LottiePlayer";
import SubNav from "./SubNav";
import Timeline from "./Timeline";

console.log(`Crew Django Starter ${window.bridge.VERSION}`);

if (process.env.NODE_ENV !== "development") {
  try {
    Sentry.init({
      dsn: window.bridge.SENTRY_DSN,
      release: window.bridge.VERSION,
      environment: window.bridge.ENV,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 0.1,
      allowUrls: "crew-django-starter",
    });
  } catch (error) {
    console.log("Error setting up Sentry");
  }
}

const Stimulus = Application.start();
Stimulus.register("ajaxForm", AjaxForm);
Stimulus.register("autoplayingVideo", AutoplayingVideo);
Stimulus.register("burger", Burger);
Stimulus.register("carousel", Carousel);
Stimulus.register("fadeOnScroll", FadeOnScroll);
Stimulus.register("lottiePlayer", LottiePlayer);
Stimulus.register("mobileMenu", MobileMenu);
Stimulus.register("scroll", Scroll);
Stimulus.register("subNav", SubNav);
Stimulus.register("timeline", Timeline);
