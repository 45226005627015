import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["scrollToEl"];
  connect() {
    this.top = this.scrollToElTarget.getBoundingClientRect().top;
    // Binds
    this.scroll = this.scroll.bind(this);
  }

  scroll() {
    this.scrollToElTarget.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }
}
