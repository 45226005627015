import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static outlets = ["mobileMenu"];
  connect() {
    // Binds
    this.activate = this.activate.bind(this);
  }

  activate() {
    this.element.classList.toggle("active");
    this.mobileMenuOutletElement.classList.toggle("active");
  }
}
