import { Controller } from "@hotwired/stimulus";
import { mapValues } from "./utils";
import { useIntersection } from "stimulus-use";

export default class extends Controller {
  static targets = ["elToFade"];

  connect() {
    useIntersection(this);
    this.handleRaf = this.handleRaf.bind(this);
  }

  appear() {
    window.addEventListener("scroll", this.handleRaf);
  }

  disappear() {
    cancelAnimationFrame(this.handleRaf);
    window.removeEventListener("scroll", this.handleRaf);
  }

  handleRaf() {
    const { height } = this.element.getBoundingClientRect();
    const opacity = mapValues((window.scrollY / height) * 100, 0.05, 25, 1, 0);
    if (this.elToFadeTargets.length) {
      this.elToFadeTargets.forEach((el) => {
        el.style.opacity = opacity;
      });
    }
  }
}
