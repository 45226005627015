import { Controller } from "@hotwired/stimulus";
import { useWindowResize } from "stimulus-use";

export default class extends Controller {
  connect() {
    // Vars
    this.windowHeight = window.innerHeight;
    this.opacityStartPoint = this.windowHeight / 2;

    // Binds
    this.handleRaf = this.handleRaf.bind(this);
    useWindowResize(this);

    // Setup
    requestAnimationFrame(this.handleRaf);
  }

  handleRaf() {
    const { top } = this.element.getBoundingClientRect();
    if (top <= this.opacityStartPoint) {
      this.element.classList.add("active");
    } else {
      this.element.classList.remove("active");
    }
    requestAnimationFrame(this.handleRaf);
  }

  windowResize({ height }) {
    this.windowHeight = height;
  }
}
